import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const liminaire = () => (
  <Layout>
    <SEO title="Dernières oeillades - Jeu interdit" />
    <h3 className='underline-title'>Jeu interdit</h3>
    <br />
    <br />
    <div className="table-responsive">
      <p>
        Leur jeu a une règle<br />
        <span className="par-starting"></span>règle acceptée<br />
        Ils jouent donc le jeu public<br />
        <span className="par-starting"></span>l'athlétisme <br />
        Jouez plutôt leur jeu <br />
        <span className="par-starting"></span>jeu olympique <br />
        Ne jouez pas votre jeu<br />
        <span className="par-starting"></span>jeu privé<br />
      </p>
      <p>
        De grâce<br />
        <span className="par-starting"></span>ne jouez pas DANS le jeu<br />
        <span className="par-starting"></span>c'est trouble fête <br />
        Ne faites pas le contre jeu <br />
        <span className="par-starting"></span>c'est du Baccara<br />
        Jouez donc le jeu:<br />
        <span className="par-starting"></span>jeu de Mexico<br />
        <span className="par-starting"></span>je de Munich<br />
        NE JOUEZ PAS DANS LE JEU!!!<br />
        <span className="par-starting"></span>jeu du neuvième mois<br />
        <span className="par-starting"></span>septembrisades munichoises <br />
        <span className="par-starting"></span>jeu anathématisé <br />
      </p>
      <p>
        Bons joueurs que vous soyez! <br />
        Pour quelle cause que vous jouez! <br />
      </p>
      <p>
        Donc <br />
        Ne jouez point dans le jeu!<br />
      </p>
    </div>
    <br />
    <br />
    <Link to="/dernieres-oeillades-table-des-matieres">Table des matières</Link>
  </Layout>
)

export default liminaire
